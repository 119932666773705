/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import Scrollspy from "react-scrollspy";

export default function Pagination() {
  return (
    <div className="min-h-screen fixed left-0 flex-col justify-center items-center xl:w-44 lg:w-20 z-10 top-0 bottom-0 lg:flex hidden">
      <Scrollspy
        items={["hero", "the-donkeys", "faqs"]}
        currentClassName="active-scroll-spy"
        className="grid grid-cols-1 gap-3"
      >
        <li
          className="w-4 h-4 rounded-full bg-white opacity-10"
          role="button"
          onClick={() => (window.location.href = "/#hero")}
        >
          <a href="#hero"></a>
        </li>
        <li
          className="w-4 h-4 rounded-full bg-white opacity-10"
          role="button"
          onClick={() => (window.location.href = "/#the-donkeys")}
        >
          <a href="#the-donkeys"></a>
        </li>
        <li
          className="w-4 h-4 rounded-full bg-white opacity-10"
          role="button"
          onClick={() => (window.location.href = "/#faqs")}
        >
          <a href="#faqs"></a>
        </li>
      </Scrollspy>
    </div>
  );
}
