import React from "react";
import Footer from "../components/Footer";
import Cta from "../components/Home/Cta";
import Faqs from "../components/Home/Faqs";
import Hero from "../components/Home/Hero";
import Team from "../components/Home/Team";
import Traits from "../components/Home/Traits";
import Pagination from "../components/Pagination";
import Doma from "../components/Home/Doma";
import MobileDoma from "../components/Home/MobileDoma";

function Home({ onOpen }: any) {
  return (
    <div className="relative scroll-smooth bg-black">
      <Hero onOpen={onOpen} />
      <Cta />
      <Doma />
      <MobileDoma />
      <Traits />
      <Team />
      <Faqs />
      <Footer />
      <Pagination />
    </div>
  );
}

export default Home;
