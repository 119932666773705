import React from "react";
import config from "../../utils/config";

export default function Cta() {
  return (
    <div className="bg-[#1e1e1eff] lg:py-8 py-10">
      <div className="w-10/12 mx-auto flex lg:flex-row flex-col items-center justify-between">
        <div className="lg:order-1 order-2 flex flex-col lg:items-start items-center lg:space-y-3 space-y-6">
          <div className="lg:text-left text-center">
            <span className="font-Lemon lg:text-8xl text-6xl text-white">
              INTO THE DONKEVERSE…
            </span>
          </div>
          <div className="mt-2 lg:text-left text-center">
            <p className="font-DDIN text-base text-white">
              Join our Discord community and follow us on Twitter in order to be
              up to date with the latest news and announcements!
            </p>
          </div>
          <div className="pt-4 lg:pb-0 pb-4 lg:w-auto w-full space-x-3 flex items-center">
            <a
              className="lg:px-6 lg:w-auto w-full py-2 font-DDIN xl:text-lg text-base text-center text-white bg-[#5765f2ff]"
              href={config.discordLink}
              target="_blank"
              rel="noreferrer"
            >
              Join Our Discord
            </a>
            <a
              className="lg:px-6 lg:w-auto w-full py-2 font-DDIN xl:text-lg text-base text-center text-white bg-[#469be9ff]"
              href={config.twitterLink}
              target="_blank"
              rel="noreferrer"
            >
              Follow Our Twitter
            </a>
          </div>
        </div>
        <div className="lg:order-2 order-1">
          <img
            src="/static/images/donkeverse_banner_white.png"
            alt=""
            className="w-auto lg:h-[240px] h-auto lg:mb-0 mb-10"
          />
        </div>
      </div>
    </div>
  );
}
