import React from "react";

export default function Traits() {
  const images = {
    first: [
      "/static/images/donkey/1.jpg",
      "/static/images/donkey/2.jpg",
      "/static/images/donkey/4.jpg",
      "/static/images/donkey/3.jpg",
      "/static/images/donkey/9.jpg",
      "/static/images/donkey/6.jpg",
      "/static/images/donkey/7.jpg",
      "/static/images/donkey/8.jpg",
      "/static/images/donkey/13.jpg",
      "/static/images/donkey/10.jpg",
    ],
    second: [
      "/static/images/donkey/11.jpg",
      "/static/images/donkey/12.jpg",
      "/static/images/donkey/14.jpg",
      "/static/images/donkey/5.jpg",
      "/static/images/donkey/17.jpg",
      "/static/images/donkey/15.jpg",
      "/static/images/donkey/16.jpg",
      "/static/images/donkey/18.jpg",
      "/static/images/donkey/19.jpg",
      "/static/images/donkey/20.jpg",
    ],
  };

  return (
    <section
      className="bg-black xl:py-36 lg:py-18 py-0 relative"
      id="the-donkeys"
    >
      <div className="space-y-5">
        <div className="marquee-container space-x-3">
          <div className="marquee">
            <div className="flex items-center flex-nowrap space-x-3 lg:h-80 h-60">
              {images.first.map((items, index) => (
                <img src={items} alt="one" className="h-full" key={index} />
              ))}
            </div>
          </div>
          <div className="marquee">
            <div className="flex items-center flex-nowrap space-x-3 lg:h-80 h-60">
              {images.first.map((items, index) => (
                <img src={items} alt="one" className="h-full" key={index} />
              ))}
            </div>
          </div>
        </div>
        <div className="marquee-container space-x-3">
          <div className="marquee-reverse">
            <div className="flex items-center flex-nowrap space-x-3 lg:h-80 h-60">
              {images.second.map((items, index) => (
                <img src={items} alt="one" className="h-full" key={index} />
              ))}
            </div>
          </div>
          <div className="marquee-reverse">
            <div className="flex items-center flex-nowrap space-x-3 lg:h-80 h-60">
              {images.second.map((items, index) => (
                <img src={items} alt="one" className="h-full" key={index} />
              ))}
            </div>
          </div>
        </div>
        {/* <div className="xl:hidden block space-y-3">
          <div className="marquee-container space-x-3 lg:hidden flex">
            <div className="marquee">
              <div className="flex items-center flex-nowrap space-x-3 lg:h-80 h-60">
                {images.first.map((items, index) => (
                  <img src={items} alt="one" className="h-full" key={index} />
                ))}
              </div>
            </div>
            <div className="marquee">
              <div className="flex items-center flex-nowrap space-x-3 lg:h-80 h-60">
                {images.first.map((items, index) => (
                  <img src={items} alt="one" className="h-full" key={index} />
                ))}
              </div>
            </div>
          </div>
          <div className="marquee-container space-x-3">
            <div className="marquee-reverse">
              <div className="flex items-center flex-nowrap space-x-3 lg:h-80 h-60">
                {images.second.map((items, index) => (
                  <img src={items} alt="one" className="h-full" key={index} />
                ))}
              </div>
            </div>
            <div className="marquee-reverse">
              <div className="flex items-center flex-nowrap space-x-3 lg:h-80 h-60">
                {images.second.map((items, index) => (
                  <img src={items} alt="one" className="h-full" key={index} />
                ))}
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
}
