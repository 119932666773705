import React from "react";
import classnames from "classnames";

interface Props {
  title: string;
  children: React.ReactNode;
}

const Faq = ({ title, children }: Props) => {
  const [visible, setVisible] = React.useState(false);

  const classContainer = classnames(
    "font-DDIN text-lg transtion-all duration-200 ease-in-out text-white mt-2 max-w-[90%]",
    {
      "max-h-[0] overflow-y-hidden": !visible,
      "max-h-[200px]": visible,
    }
  );

  return (
    <li className="py-4">
      <div
        className="flex items-center justify-between"
        role="button"
        onClick={() => setVisible((prev) => !prev)}
      >
        <span className="text-2xl text-white font-Lemon">{title}</span>

        {!visible ? (
          <svg
            className="w-10 h-10 text-white"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
              clipRule="evenodd"
            />
          </svg>
        ) : (
          <svg
            className="w-10 h-10 text-white"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
        )}
      </div>
      <div className={classContainer}>{children}</div>
    </li>
  );
};

export default function Faqs() {
  return (
    <section
      className="bg-black xl:py-36 lg:py-18 py-18 relative 2xl:px-56 xl:px-36 lg:px-24 px-6"
      id="faqs"
    >
      <h1 className="font-Lemon text-8xl text-white">Faqs</h1>

      <div className="grid xl:grid-cols-2 grid-cols-1 lg:gap-32 gap-0 mt-10">
        <ul className="divide-y-2 divide-white xl:block hidden">
          <Faq title="Who is the artist behind Donkeverse?">
            Every Donke NFT is unique and created from over 250 hand-drawn
            traits by renown contemporary artist,{" "}
            <a
              href="https://www.instagram.com/naufalabshar"
              className="font-semibold"
              target="_blank"
              rel="noreferrer"
            >
              Naufal Abshar
            </a>
            . You can find the inspiration of the Donke from Naufal Abshar’s
            original real-life paintings dating back for more than a decade.
          </Faq>
          <Faq title="Where and when can I buy Donkeverse NFTs?">
            Donkes will be minted on our official website sometime in Q1 2022.
            The official link will be shared in our Discord prior to the mint
            day.
          </Faq>
          <Faq title="What is the supply of Donkeverse NFTs?">
            There will only ever be a total supply of 5,000 genesis Donkes.
          </Faq>
          <Faq title="What is DOMA?">
            DOMA stands for the DOnke Museum of Arts, the museum in Donkeverse.
            A lot of valuable artworks is stored in DOMA.
          </Faq>
          <Faq title="What is The Heist?">
            The Heist is the game that you will be able to play as Donke NFT
            holder. In The Heist, you will be attempting to steal valuable NFTs
            from DOMA.
          </Faq>
          <Faq title="What are the prizes can I win from playing the game?">
            The 14 Legendary Donkes, NFT collectibles from outside the
            Donkeverse ecosystem, Blue-Chips, and genesis NFT of talented
            renowned artists.
          </Faq>
        </ul>
        <ul className="divide-y-2 divide-white xl:block hidden">
          <Faq title="How do I play the game?">
            You need a Donke NFT in order to play the game. All Donkes will
            automatically earn $DONK everyday. $DONK will be burned in order to
            play the game
          </Faq>
          <Faq title="When can I play the game?">
            Donkes will be able to go on heists the latest 3-4 weeks after mint.
          </Faq>
          <Faq title="Which network are you using?">
            Donkeverse NFT will be minted on Ethereum and the game will be on
            Polygon.
          </Faq>
          <Faq title="Where is your roadmap?">
            We are currently laser-focused to deliver a fun, rewarding, gaming
            experience for our community through The Heist. In the long run, we
            have every intention to create a brand, focus on collabs, create
            live events, and so on. However, right now our main priority is to
            deliver on the game.
          </Faq>
          <Faq title="Contract addresses?">TBD</Faq>
          <Faq title="How do I stay up to date about Donkeverse?">
            Information is provided regularly on our{" "}
            <a
              target="_blank"
              href="https://www.discord.gg/donkeverse"
              rel="noreferrer"
              className="font-semibold"
            >
              Discord
            </a>{" "}
            and{" "}
            <a
              href="https://twitter.com/donkeverse"
              target="_blank"
              rel="noreferrer"
              className="font-semibold"
            >
              Twitter
            </a>
            .
          </Faq>
        </ul>

        <ul className="divide-y-2 divide-white xl:hidden block">
          <Faq title="Who is the artist behind Donkeverse?">
            Every Donke NFT is unique and created from over 250 hand-drawn
            traits by renown contemporary artist,{" "}
            <a
              href="https://www.instagram.com/naufalabshar"
              className="font-semibold"
              target="_blank"
              rel="noreferrer"
            >
              Naufal Abshar
            </a>
            . You can find the inspiration of the Donke from Naufal Abshar’s
            original real-life paintings dating back for more than a decade.
          </Faq>
          <Faq title="Where and when can I buy Donkeverse NFTs?">
            Donkes will be minted on our official website sometime in Q1 2022.
            The official link will be shared in our Discord prior to the mint
            day.
          </Faq>
          <Faq title="What is the supply of Donkeverse NFTs?">
            There will only ever be a total supply of 5,000 genesis Donkes.
          </Faq>
          <Faq title="What is DOMA?">
            DOMA stands for the DOnke Museum of Arts, the museum in Donkeverse.
            A lot of valuable artworks is stored in DOMA.
          </Faq>
          <Faq title="What is The Heist?">
            The Heist is the game that you will be able to play as Donke NFT
            holder. In The Heist, you will be attempting to steal valuable NFTs
            from DOMA.
          </Faq>
          <Faq title="What are the prizes can I win from playing the game?">
            The 14 Legendary Donkes, NFT collectibles from outside the
            Donkeverse ecosystem, Blue-Chips, and genesis NFT of talented
            renowned artists.
          </Faq>
          <Faq title="How do I play the game?">
            You need a Donke NFT in order to play the game. All Donkes will
            automatically earn $DONK everyday. $DONK will be burned in order to
            play the game
          </Faq>
          <Faq title="When can I play the game?">
            Donkes will be able to go on heists the latest 3-4 weeks after mint.
          </Faq>
          <Faq title="Which network are you using?">
            Donkeverse NFT will be minted on Ethereum and the game will be on
            Polygon.
          </Faq>
          <Faq title="Where is your roadmap?">
            We are currently laser-focused to deliver a fun, rewarding, gaming
            experience for our community through The Heist. In the long run, we
            have every intention to create a brand, focus on collabs, create
            live events, and so on. However, right now our main priority is to
            deliver on the game.
          </Faq>
          <Faq title="Contract addresses?">TBD</Faq>
          <Faq title="How do I stay up to date about Donkeverse?">
            Information is provided regularly on our{" "}
            <a
              target="_blank"
              href="https://www.discord.gg/donkeverse"
              rel="noreferrer"
              className="font-semibold"
            >
              Discord
            </a>{" "}
            and{" "}
            <a
              href="https://twitter.com/donkeverse"
              target="_blank"
              rel="noreferrer"
              className="font-semibold"
            >
              Twitter
            </a>
            .
          </Faq>
        </ul>
      </div>
    </section>
  );
}
