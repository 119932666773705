import classNames from "classnames";
import React from "react";

interface TeamItemProps {
  name: string;
  role: string;
  description?: string;
  thumbnailUrl: string;
  social?: any;
  color: string;
}

function TeamItem({
  name,
  role,
  social,
  description,
  thumbnailUrl,
  color,
}: TeamItemProps) {
  return (
    <div className="group relative">
      <header className="team-people-image">
        <img src={thumbnailUrl} alt="" />
      </header>
      <footer className="text-center py-4">
        <h5 className="text-white xl:text-2xl text-xl font-Lemon tracking-wide">
          {name}
        </h5>
        <p className="xl:text-sm text-xs text-white font-DDIN">{role}</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 70.58 10.95"
          className="h-3 mt-3 mx-auto"
          style={{ color }}
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                className="fill-current "
                d="M68.94.2A298.68,298.68,0,0,0,3.88,5,5,5,0,0,0,.16,8.22c-.46,1.47.07,3,1.51,2.7A298.68,298.68,0,0,1,66.73,6.17C69.13,6.26,72.72.34,68.94.2Z"
              />
            </g>
          </g>
        </svg>
      </footer>

      <div className="absolute left-0 top-0 right-0 opacity-0 group-hover:opacity-100 transition duration-300 team-people-border overflow-hidden z-20">
        <header className=" bg-black">
          <img src={thumbnailUrl} alt="" />
        </header>
        <footer className="text-center py-4 bg-black">
          <h5 className="text-white text-2xl font-Lemon tracking-wide">
            {name}
          </h5>
          <p className="text-sm text-white font-DDIN capitalize">{role}</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 70.58 10.95"
            className="h-3 mt-3 mx-auto"
            style={{ color }}
          >
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_1-2" data-name="Layer 1">
                <path
                  className="fill-current "
                  d="M68.94.2A298.68,298.68,0,0,0,3.88,5,5,5,0,0,0,.16,8.22c-.46,1.47.07,3,1.51,2.7A298.68,298.68,0,0,1,66.73,6.17C69.13,6.26,72.72.34,68.94.2Z"
                />
              </g>
            </g>
          </svg>
          <div className="mt-3">
            <ul className="text-white space-x-3">
              {social?.map((items: any, index: any) => {
                if (items.name === "twitter") {
                  return (
                    <li className="inline-block" key={index}>
                      <a href={items.href} target="_blank" rel="noreferrer">
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="currentColor"
                          className="w-4 h-4"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M31.5239 6.58266C30.4106 7.07599 29.2146 7.40932 27.9573 7.55999C29.2546 6.78371 30.2252 5.56195 30.6879 4.12266C29.4691 4.84664 28.1351 5.35625 26.7439 5.62932C25.8084 4.63048 24.5693 3.96843 23.219 3.74596C21.8687 3.52349 20.4827 3.75305 19.2763 4.39899C18.0698 5.04494 17.1103 6.07114 16.5468 7.31826C15.9833 8.56538 15.8473 9.96366 16.1599 11.296C13.6902 11.172 11.2741 10.5301 9.0685 9.41186C6.8629 8.29367 4.91706 6.72421 3.35727 4.80532C2.82393 5.72532 2.51727 6.79199 2.51727 7.92799C2.51667 8.95065 2.76851 9.95765 3.25044 10.8596C3.73237 11.7616 4.42948 12.5307 5.27993 13.0987C4.29364 13.0673 3.3291 12.8008 2.4666 12.3213V12.4013C2.4665 13.8356 2.96264 15.2258 3.87084 16.336C4.77905 17.4461 6.04336 18.2079 7.44927 18.492C6.53431 18.7396 5.57505 18.7761 4.64393 18.5987C5.0406 19.8328 5.81326 20.912 6.85376 21.6852C7.89425 22.4584 9.15049 22.8869 10.4466 22.9107C8.24638 24.6379 5.52912 25.5748 2.73193 25.5707C2.23644 25.5708 1.74137 25.5419 1.24927 25.484C4.08857 27.3096 7.39372 28.2784 10.7693 28.2747C22.1959 28.2747 28.4426 18.8107 28.4426 10.6027C28.4426 10.336 28.4359 10.0667 28.4239 9.79999C29.639 8.92129 30.6878 7.83319 31.5213 6.58666L31.5239 6.58266Z"
                            fill="currentColor"
                          />
                        </svg>
                      </a>
                    </li>
                  );
                }

                if (items.name === "instagram") {
                  return (
                    <li className="inline-block" key={index}>
                      <a href={items.href} target="_blank" rel="noreferrer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          className="w-4 h-4"
                          fill="currentColor"
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M12 2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2zm0 5a5 5 0 1 0 0 10 5 5 0 0 0 0-10zm6.5-.25a1.25 1.25 0 0 0-2.5 0 1.25 1.25 0 0 0 2.5 0zM12 9a3 3 0 1 1 0 6 3 3 0 0 1 0-6z" />
                        </svg>
                      </a>
                    </li>
                  );
                }

                return (
                  <li className="inline-block" key={index}>
                    <a href={items.href} target="_blank" rel="noreferrer">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        fill="none"
                        className="w-4 h-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                        />
                      </svg>
                    </a>
                  </li>
                );
              })}
            </ul>
            <p className="text-xs font-DDIN text-white mt-3 max-w-[160px] mx-auto">
              {description}
            </p>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default function Team() {
  const teams = [
    {
      name: "NAUFAL ABSHAR",
      role: "Artist & Creative Director ",
      thumbnailUrl: "/static/images/team/naufal.jpg",
      description:
        "Contemporary artist. Always dreaming about New York. The OG Donke. ",
      lineColor: "#9a2168",
      social: [
        {
          name: "twitter",
          href: "https://twitter.com/naufalabshar",
        },
        {
          name: "instagram",
          href: "https://instagram.com/naufalabshar",
        },
        {
          name: "web",
          href: "https://www.naufalabshar.com/",
        },
      ],
    },
    {
      name: "JEFFREY SCHOLZ",
      role: "Tech Lead",
      thumbnailUrl: "/static/images/team/jeff.jpg",
      lineColor: "#12bafb",
      description:
        "Ex-Senior Software Manager at Yahoo. Two patents in AI. Built a 4 million dollar start up on the side. Minted the wrong coins in 2015. ",
      social: [
        {
          name: "twitter",
          href: "https://twitter.com/jeyffre",
        },
        {
          name: "web",
          href: "https://linkedin.com/in/jeffreyscholz/ ",
        },
      ],
    },
    {
      name: "BEOLICIOUS",
      role: "Strategy & Project Manager",
      thumbnailUrl: "/static/images/team/beo.jpg",
      lineColor: "#6b6a68",
      description:
        "Ex-Googler. SEA eSports org board member. eSports team investor. Father of one. Constantly begging for whitelists.",
      social: [
        {
          name: "twitter",
          href: "https://twitter.com/sup3rgeo",
        },
      ],
    },
    {
      name: "C_HELLO",
      role: "Marketing & Community",
      thumbnailUrl: "/static/images/team/CH.jpg",
      lineColor: "#446bed",
      description:
        "Ex-banker turned entrepreneur. Led various fundraisings for startups. Youth Leadership Awardee, United Nations. Passionate about woman (and degens) in tech. ",
      social: [
        {
          name: "twitter",
          href: "https://twitter.com/cathhalim",
        },
      ],
    },
    {
      name: "JODIKUIK",
      role: "Artist",
      lineColor: "#9a2168",
      thumbnailUrl: "/static/images/team/unseenjodi.jpg",
      description:
        "Videographer in the morning. Artist at night. Making art by mixing random object. Sleep deprived.",
      social: [
        {
          name: "twitter",
          href: "https://twitter.com/jodikuik",
        },
      ],
    },
    {
      name: "MADNESS_YOKAI",
      role: "Artist",
      lineColor: "#9a2168",
      thumbnailUrl: "/static/images/team/madness yokai.jpg",
      description:
        "Creative madness from a leading global advertising agency. Dreamt he was a world famous artist. Then he woke up.",
      social: [
        {
          name: "twitter",
          href: "https://twitter.com/madness_yokai",
        },
      ],
    },
    {
      name: "PUDGY",
      role: "Community",
      thumbnailUrl: "/static/images/team/pudgy.jpg",
      lineColor: "#12bafb",
      description:
        "Entrepreneur. Prestige 40 under 40. TEDx Speaker. Loves his sausage dog. Easily bribed with tequila.  ",
      social: [
        {
          name: "twitter",
          href: "https://twitter.com/pudgylovesyou",
        },
      ],
    },
    {
      name: "JOFND",
      role: "Community",
      lineColor: "#12bafb",
      thumbnailUrl: "/static/images/team/jofnd.jpg",
      description:
        "Wanted to do strategy consulting, but couldn’t get into MBB so now into WEB3. Launched U_Unicorns and Property’s NFT among others. Moonlights as an ape.",
      social: [
        {
          name: "twitter",
          href: "https://twitter.com/jofnd_eth",
        },
      ],
    },
    {
      name: "PEENUT",
      role: "Community",
      lineColor: "#446bed",
      thumbnailUrl: "/static/images/team/peenut.jpg",
      description:
        "Works with air quality. Vibes to jazz tunes. Low(high)key wants to be an artist. ",
      social: [
        {
          name: "twitter",
          href: "https://twitter.com/peenut222",
        },
      ],
    },
    {
      name: "THEICEFORGED",
      role: "Community",
      lineColor: "#12bafb",
      thumbnailUrl: "/static/images/team/the ice forge.jpg",
      description:
        "Corporate slave by day, venture angel investor by night. Donkeverse all day. Exudes BDE - Big Donke Energy. ",
      social: [
        {
          name: "twitter",
          href: "https://twitter.com/theiceforged",
        },
      ],
    },
    {
      name: "GALANGSTER",
      role: "Community",
      lineColor: "#6b6a68",
      thumbnailUrl: "/static/images/team/galangster.jpg",
      description:
        "Part CEO of creative agency, part Youth Minister, full time poop scooper for his bunny. ",
      social: [
        {
          name: "twitter",
          href: "https://twitter.com/galangster",
        },
      ],
    },
    {
      name: "ANFTHONY",
      role: "Community",
      thumbnailUrl: "/static/images/team/anfthony.jpg",
      lineColor: "#9a2168",
      description:
        "Aquaculture entrepreneur, but also trades crypto. Aspiring degen.",
      social: [
        {
          name: "twitter",
          href: "https://twitter.com/anfthony_",
        },
      ],
    },
  ];

  const [selection, setSelection] = React.useState<any>(teams[0]);
  const [active, setActive] = React.useState<any>(null);

  return (
    <section
      className="bg-black 2xl:px-56 xl:px-36 lg:px-24 px-6 xl:py-36 lg:py-16 py-10"
      id="team"
    >
      <h1 className="font-Lemon text-8xl text-white">Team</h1>

      <div className="grid-cols-6 gap-6 mt-10 xl:grid hidden">
        {teams.map((items, index) => {
          return (
            <TeamItem
              thumbnailUrl={items.thumbnailUrl}
              name={items.name}
              role={items.role}
              social={items.social}
              color={items.lineColor}
              key={index}
              description={items.description}
            />
          );
        })}
      </div>

      <div className="xl:hidden grid grid-cols-4 gap-4 mt-10">
        {teams.map((items, index) => {
          return (
            <button
              className="group relative"
              onClick={() => {
                setSelection(items);
                setActive(index);
              }}
              onBlur={() => setActive(null)}
            >
              <header>
                <img
                  src={items.thumbnailUrl}
                  alt=""
                  className="team-people-image"
                />
              </header>

              <img
                src={items.thumbnailUrl}
                alt=""
                className={classNames(
                  "team-people-image absolute people-scale inset-0 w-full h-full z-20",
                  {
                    "opacity-0": index !== active,
                    "opacity-100": index === active,
                  }
                )}
              />
            </button>
          );
        })}
      </div>

      <div className="xl:hidden block mt-10">
        <div className="divide-y-2 divide-white">
          <div className="flex items-center space-x-3">
            <span className="text-2xl font-Lemon text-white py-3">
              {selection?.name}
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 14.1 31.46"
              className="h-8"
              style={{ color: selection?.lineColor }}
            >
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <path
                    className="fill-current"
                    d="M10.66.36A86.88,86.88,0,0,0,0,28.19a3,3,0,0,0,1.32,2.59c.85.69,1.93,1,2.06.3A86.48,86.48,0,0,1,14,3.26C14.59,2.32,11.55-1.11,10.66.36Z"
                  />
                </g>
              </g>
            </svg>
            <span className="text-base font-DDIN text-white py-3 capitalize">
              {selection?.role}
            </span>
          </div>

          <p className="text-lg font-DDIN text-white py-3">
            {selection?.description}
          </p>
          <ul className="text-white space-y-3 py-3">
            {selection?.social?.map((items: any, index: any) => {
              if (items.name === "twitter") {
                return (
                  <li key={index}>
                    <a
                      href={items.href}
                      className="flex items-center space-x-3"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="currentColor"
                        className="w-4 h-4"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M31.5239 6.58266C30.4106 7.07599 29.2146 7.40932 27.9573 7.55999C29.2546 6.78371 30.2252 5.56195 30.6879 4.12266C29.4691 4.84664 28.1351 5.35625 26.7439 5.62932C25.8084 4.63048 24.5693 3.96843 23.219 3.74596C21.8687 3.52349 20.4827 3.75305 19.2763 4.39899C18.0698 5.04494 17.1103 6.07114 16.5468 7.31826C15.9833 8.56538 15.8473 9.96366 16.1599 11.296C13.6902 11.172 11.2741 10.5301 9.0685 9.41186C6.8629 8.29367 4.91706 6.72421 3.35727 4.80532C2.82393 5.72532 2.51727 6.79199 2.51727 7.92799C2.51667 8.95065 2.76851 9.95765 3.25044 10.8596C3.73237 11.7616 4.42948 12.5307 5.27993 13.0987C4.29364 13.0673 3.3291 12.8008 2.4666 12.3213V12.4013C2.4665 13.8356 2.96264 15.2258 3.87084 16.336C4.77905 17.4461 6.04336 18.2079 7.44927 18.492C6.53431 18.7396 5.57505 18.7761 4.64393 18.5987C5.0406 19.8328 5.81326 20.912 6.85376 21.6852C7.89425 22.4584 9.15049 22.8869 10.4466 22.9107C8.24638 24.6379 5.52912 25.5748 2.73193 25.5707C2.23644 25.5708 1.74137 25.5419 1.24927 25.484C4.08857 27.3096 7.39372 28.2784 10.7693 28.2747C22.1959 28.2747 28.4426 18.8107 28.4426 10.6027C28.4426 10.336 28.4359 10.0667 28.4239 9.79999C29.639 8.92129 30.6878 7.83319 31.5213 6.58666L31.5239 6.58266Z"
                          fill="currentColor"
                        />
                      </svg>

                      <span>{items.href.replace("https://", "")}</span>
                    </a>
                  </li>
                );
              }

              if (items.name === "instagram") {
                return (
                  <li key={index}>
                    <a
                      href={items.href}
                      className="flex items-center space-x-3"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        className="w-4 h-4"
                        fill="currentColor"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2zm0 5a5 5 0 1 0 0 10 5 5 0 0 0 0-10zm6.5-.25a1.25 1.25 0 0 0-2.5 0 1.25 1.25 0 0 0 2.5 0zM12 9a3 3 0 1 1 0 6 3 3 0 0 1 0-6z" />
                      </svg>
                      <span>{items.href.replace("https://", "")}</span>
                    </a>
                  </li>
                );
              }

              return (
                <li key={index}>
                  <a
                    href={items.href}
                    className="flex items-center space-x-3"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      fill="none"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                      />
                    </svg>
                    <span>{items.href.replace("https://", "")}</span>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
}
