import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Hero from "../components/TheHeist/Hero";
import HowToHeist from "../components/TheHeist/HowToHeist";

export default function TheHeist({ onOpen }: any) {
  return (
    <div className="bg-black relative">
      <Header className="w-full" onOpen={onOpen} />

      <Hero />
      <HowToHeist />
      <Footer />
    </div>
  );
}
