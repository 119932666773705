import React from "react";

export default function Hero() {
  return (
    <section className="grid lg:grid-cols-12 grid-cols-1 lg:gap-10 gap-0 2xl:max-w-screen-2xl 2xl:px-0 lg:px-20 px-6 mx-auto lg:my-28 my-8">
      <div className="xl:col-span-5 lg:col-span-6 col-span-12 flex lg:flex-row flex-col justify-end">
        <img
          src="/static/images/the-heist.png?v2"
          alt="the-heist"
          className="w-[75%] h-auto lg:hidden block"
        />

        <video
          src="/static/video/doma-the-heist.mp4"
          controls
          className="w-full"
        />
      </div>
      <div className="xl:col-span-7 lg:col-span-6 col-span-12 space-y-3">
        <img
          src="/static/images/the-heist.png"
          alt="the-heist"
          className="w-auto xl:h-32 lg:h-20 h-auto lg:block hidden"
        />
        <div className="xl:space-y-5 space-y-3 lg:pt-0 pt-10">
          <p className="text-xl font-DDIN text-white xl:max-w-lg max-w-full">
            The Heist will commence shortly after post-mint.
          </p>

          <p className="text-xl font-DDIN text-white xl:max-w-lg max-w-full">
            Donkes will need resources ($DONK) in order to fund their
            operations.
          </p>

          <p className="text-xl font-DDIN text-white xl:max-w-lg max-w-full">
            Being the resourceful and hardworking creatures that they are, it
            will only be a matter of time.
          </p>

          <p className="text-xl font-DDIN text-white xl:max-w-lg max-w-full">
            With enough resources, wise planning, and a bit of luck, some of
            them might be able to pull this off, undetected...
          </p>
        </div>
      </div>
    </section>
  );
}
