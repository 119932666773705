import React from "react";
import { Link } from "react-router-dom";
import Header from "../Header";

export default function Hero({ onOpen }: any) {
  return (
    <div className="relative min-h-screen section-hero-image" id="hero">
      <Header className="absolute top-0 w-full z-10" onOpen={onOpen} />

      <img
        className="2xl:w-[50vw] xl:w-[60vw] lg:block hidden"
        src="/static/images/home_hero.jpg"
        alt="hero"
      />

      <div className="flex xl:flex-row flex-col justify-end items-end w-full h-full absolute top-0 bottom-0 right-0">
        <div className="2xl:w-[78vw] xl:w-[70vw] w-full h-full section-hero-gradient 2xl:pl-[30vw] xl:pl-72 pl-0 flex flex-col xl:justify-start justify-end">
          <div className="w-full lg:px-8 px-5 2xl:mt-52 mt-44 xl:mb-0 mb-20">
            <p className="text-white lg:text-3xl text-2xl font-DDIN">
              Welcome to the
            </p>
            <h2 className="text-white font-Lemon lg:text-8xl text-6xl">
              Donkeverse
            </h2>
            <div className="space-y-4 mt-6">
              <p className="text-white text-base font-DDIN xl:max-w-xl max-w-none">
                The museum is opening soon, and 5,000 Donkes are secretly
                planning to commit <span className="bg-[#FAE44E] text-black p-1">the greatest heist the metaverse has ever
                seen.</span>
              </p>
              <p className="text-white text-base font-DDIN xl:max-w-xl max-w-none">
                The museum within Donkeverse, also known as DOMA, is managed by
                the curator, and will house valuable collectibles, which
                includes NFTs from beyond the Donkeverse ecosystem.
              </p>
              <p className="text-white  text-base font-DDIN xl:max-w-xl max-w-none">
                Ownership of a Donke NFT doubles as your membership inside the
                Donkeverse and will allow you to partake in <span className="font-semibold">The Heist</span>. Through
                this game, holders will have a chance steal the valuable NFTs
                collected inside DOMA.
              </p>
              <p className="text-white  text-base font-DDIN xl:max-w-xl max-w-none">
                Each Donke is programmatically generated from over 250+ traits,
                and handcrafted by renowned contemporary artist, <span className="font-semibold">Naufal Abshar</span>.
              </p>
            </div>
            <div className="xl:flex hidden items-center space-x-5 mt-3">
              <span className="text-white  text-base font-DDIN">Join</span>
              <img
                alt="the-heist"
                className="h-20"
                src="/static/images/the-heist.png"
              />
              <span className="text-white  text-base font-DDIN">
                Become NOTORIOUS. Steal ART.
              </span>
            </div>
            <div className="xl:block hidden">
              <p className="font-edosz text-[#FAE44E] text-3xl transform -rotate-6">
                THE HEIST DEMO: pudgy’s museum <br /> available now!
              </p>
              <div className="ml-60 -mt-6">
                <a
                  href="https://donkeverse-minigame.netlify.app"
                  target="_blank"
                  rel="noreferrer"
                  className="2xl:w-[12vw] xl:w-[18vw]"
                >
                  <img
                    className="2xl:w-[12vw] xl:w-[18vw]"
                    src="/static/images/the-heist-click-here.png"
                    alt="hero-homepage"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
