import React from "react";

export default function HowToHeist() {
  return (
    <section className="mx-auto xl:w-[90%] lg:w-[90%] w-full lg:px-0 px-6 relative mb-20">
      <h1 className="2xl:text-[5vw] xl:text-6xl lg:text-5xl font-BebasNeue text-white mb-20 mt-8 absolute top-0 lg:block hidden">
        HOW TO HEIST
      </h1>
      <img
        className="w-full h-auto lg:block hidden"
        src="/static/images/how-to-heist.png"
        alt="how-to-heist"
      />

      <div className="lg:hidden block space-y-3">
        <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
          <div>
            <h1 className="text-6xl font-BebasNeue text-white xl:mb-20 mb-8 mt-8">
              HOW TO HEIST
            </h1>
            <img
              className="w-full h-auto"
              src="/static/images/the-heist-1.png"
              alt="the-heist-1"
            />
          </div>
          <img
            className="w-full h-auto"
            src="/static/images/the-heist-2.png"
            alt="the-heist-1"
          />
        </div>

        <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
          <img
            className="w-full h-auto"
            src="/static/images/the-heist-3.png"
            alt="the-heist-1"
          />
          <img
            className="w-full h-auto"
            src="/static/images/the-heist-4.png"
            alt="the-heist-1"
          />
        </div>
      </div>
    </section>
  );
}
