import React from "react";

export default function MobileDoma() {
  return (
    <section className="bg-black xl:hidden block">
      <div className="px-6 absolute w-full">
        <h1 className="font-BebasNeue text-5xl text-white">WHAT’S INSIDE</h1>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 375.97 81.49"
          className="text-white md:h-16 h-16 w-auto"
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                className="fill-current"
                d="M57.21,13.07A29.05,29.05,0,0,0,46.86,4.3a31.83,31.83,0,0,0-14.6-3.17H3.4A3.2,3.2,0,0,0,1,2.15,3.36,3.36,0,0,0,0,4.53V77a3.35,3.35,0,0,0,1,2.37,3.2,3.2,0,0,0,2.44,1H32.26q11.31,0,18.62-5.37A32.11,32.11,0,0,0,61.74,60.61a53.41,53.41,0,0,0,3.57-19.86,55.55,55.55,0,0,0-2-14.94A38.59,38.59,0,0,0,57.21,13.07Z"
              />
              <path
                className="fill-current"
                d="M163.4,11.49A35.26,35.26,0,0,0,151.35,3a40.26,40.26,0,0,0-30.5,0,35.35,35.35,0,0,0-12,8.49,39.23,39.23,0,0,0-7.92,12.9A45.47,45.47,0,0,0,98.1,40.75a45.41,45.41,0,0,0,2.83,16.35A39.13,39.13,0,0,0,108.85,70a35.22,35.22,0,0,0,12,8.49,40.26,40.26,0,0,0,30.5,0A35.13,35.13,0,0,0,163.4,70a38.52,38.52,0,0,0,7.87-12.9A46.29,46.29,0,0,0,174,40.75a46.35,46.35,0,0,0-2.77-16.36A38.62,38.62,0,0,0,163.4,11.49Z"
              />
              <path
                className="fill-current"
                d="M275.69,1.13a3.16,3.16,0,0,0-3.06,1.47L242.77,47.12,213.09,2.6a3.89,3.89,0,0,0-1.41-1.07,3.8,3.8,0,0,0-1.64-.4,3.11,3.11,0,0,0-2.43,1.08,3.38,3.38,0,0,0-1,2.32V77a3.44,3.44,0,0,0,.91,2.37,3,3,0,0,0,2.38,1h65.42a3.57,3.57,0,0,0,2.54-1A3.17,3.17,0,0,0,279,77V4.53a3.33,3.33,0,0,0-1-2.44A3.19,3.19,0,0,0,275.69,1.13Z"
              />
              <path
                className="fill-current"
                d="M375.74,75.83,346,3.06a4.12,4.12,0,0,0-1.31-1.65,3.45,3.45,0,0,0-2.09-.62,3.74,3.74,0,0,0-2,.51,3.22,3.22,0,0,0-1.3,1.76L309.42,76.17a2.89,2.89,0,0,0-.12,1.36,3,3,0,0,0,.8,2.1,3.26,3.26,0,0,0,2.49.84h60a3.62,3.62,0,0,0,2.38-.84,3,3,0,0,0,1-2.44A4.36,4.36,0,0,0,375.74,75.83Z"
              />
            </g>
          </g>
        </svg>
      </div>

      <img
        className="w-fullm pt-[90px] mt-10"
        src="/static/images/doma.jpeg"
        alt="doma"
      />

      <div className="space-y-3 px-8 py-8 w-full">
        <p className="text-white text-base font-DDIN">
          Post-mint, <span className="font-semibold">DOMA</span> will open and
          begin showcasing the valuable NFTs that Donkes can hope to steal and
          win in <span className="font-semibold">The Heist</span>.
        </p>

        <p className="text-white text-base font-DDIN">
          At the start of <span className="font-semibold">The Heist</span>,
          Donke holders will play in order to steal the 14 Legendary Donkes from
          DOMA. Every week after, more NFTs will be delivered inside DOMA for
          Donkes to steal. These will include blue chip NFT collectibles, as
          well as genesis NFT from talented renowned artists.
        </p>

        <p className="text-white text-base font-DDIN">
          The Donkeverse community as a whole will be participating in
          determining what NFTs that the curator should be aiming to collect
          inside DOMA moving forward.
        </p>

        <p className="text-white text-base font-DDIN">
          20% of mint proceeds and 50% of all secondary trading royalty will be
          funding the treasury for{" "}
          <span className="font-semibold">The Heist</span>
        </p>
      </div>
    </section>
  );
}
