import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Home from "./pages/Home";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import TheHeist from "./pages/TheHeist";
import WhitePaper from "./pages/WhitePaper";
import MobileHeader from "./components/MobileHeader";

const App = () => {
  const [visible, setVisible] = React.useState(false);

  const onClose = () => {
    setVisible(false);
  };

  const onOpen = () => {
    setVisible(true)
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home onOpen={onOpen} />} />
        <Route path="/the-heist" element={<TheHeist onOpen={onOpen} />} />
      </Routes>

      <MobileHeader visible={visible} onClose={onClose} />
    </Router>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
